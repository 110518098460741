import request from '@/utils/request'

export function setopenidandpwd(data) {
    return request({
        url: '/common/User/setopenidandpwd',
        method: 'get',
        params:data
    })
}
export function updatestaffinfo(data) {
    return request({
        url: '/common/User/updatestaffinfo',
        method: 'get',
        params:data
    })
}
export function getinfo(data) {
    return request({
        url: '/common/User/getinfo',
        method: 'get',
        params:data
    })
}
