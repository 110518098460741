<template>
  <div class="reset">
    <div>
      <van-field
      class="van-css"
        v-model="TableData.activityName"
        name="活动"
        label="活动"
        placeholder="活动"
        :disabled="true"
      />
      <van-field
      class="van-css"
        v-model="TableData.activityAddress"
        name="地点"
        label="地点"
        placeholder="地点"
        :disabled="true"
      />
      <van-field
      class="van-css"
        v-model="hosterString"
        name="组织者"
        label="组织者"
        placeholder="组织者"
        :disabled="true"
      />
      <van-field
      class="van-css"
        v-model="TableData.activityStart"
        name="活动时间"
        label="活动时间"
        placeholder="活动时间"
        :disabled="true"
      />
    </div>
    <van-divider></van-divider>
    <!-- <div class="tips">活动： {{ TableData.activityName }}</div>
    <div class="tips">地点： {{ TableData.activityAddress }}</div>
    <div class="tips">
      组织者：
      <span v-for="(item, index) in TableData.hoster" :key="index">
        <span v-if="index">、</span>
        {{ item }}</span
      >
    </div>
    <div class="tips">活动时间： {{ TableData.activityStart }} 开始</div> -->
    <div v-if="isShow">
      <van-field
        v-model="formData.userName"
        name="OA员工"
        label="OA员工"
        placeholder="OA员工"
        :disabled="true"
      />
      <!-- <van-field
        v-model="formData.phone"
        name="登录手机"
        label="登录手机"
        placeholder="登录手机"
        :disabled="true"
      /> -->
      <van-field
        v-model="formData.position"
        name="职位"
        label="职位"
        placeholder="职位"
        :disabled="true"
      />
      <van-field
        v-model="formData.currentstationName"
        name="部门(站)"
        label="部门(站)"
        placeholder="部门(站)"
        :disabled="true"
      />
    </div>
    <div style="margin: 16px">
      <van-button
        v-if="!isShow"
        type="info"
        style="width: 100%"
        @click="goWxAuth"
        >绑定微信</van-button
      >
      <van-button
        v-if="isShow"
        type="info"
        style="width: 100%"
        @click="onSubmit"
        :disabled="TableData.signed"
        ><span v-if="TableData.signed">您已签到</span
        ><span v-else>确认签到</span></van-button
      >
    </div>
  </div>
</template>

<script>
import { Notify, Button, Field, Form , Divider  } from "vant";
import { getinfo } from "@/api/common/user";
import { getDetailApi } from "@/api/safety/activity";
import { setToken } from "@/utils/auth";
import { getsignin } from "@/api/activity/activity";

// import { getCode } from "@/utils/common";
export default {
  name: "resetPassword",
  components: {
    [Notify.name]: Notify,
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
    [Divider.name]:Divider,
  },

  data() {
    return {
      isLoading: false,
      TableData: [],
      isShow: false,
      formData: {
        userName: "",
        // phone:"",
        position: "",
        currentstationName: "",
      },
      hosterString: "",
    };
  },
  mounted() {
    this.getDetails();
  },
  created() {},
  methods: {
    getDetails() {
      this.isShow = false;
      let ref = window.location.href;
      if (ref.indexOf("?p=") == -1) {
        this.goWxAuth();
      }
      if (ref.indexOf("?p=") != -1) {
        this.isShow = true;
        let token1 = ref.substring(ref.indexOf("?p=") + 3);
        setToken(token1);
        getinfo().then((res) => {
          console.log("res", res);
          this.formData = res.data;
        });
      }
      ref = Number(ref.substring(ref.indexOf("id=") + 3, ref.indexOf("#/")));
      getDetailApi({ id: ref }).then((res) => {
        let data = res.data;
        data.hoster = eval("(" + data.hoster + ")");
        this.TableData = data;
        this.hosterString = "";
        for (let index = 0; index < this.TableData.hoster.length; index++) {
          const item = this.TableData.hoster[index];
          if (index != 0) {
            this.hosterString += "、";
          }
          this.hosterString += item;
        }
      });
    },

    goWxAuth() {
      this.isShow = false;
      let ref = window.location.href;
      ref = Number(ref.substring(ref.indexOf("id=") + 3, ref.indexOf("#/")));
      let url = process.env.VUE_APP_WX_REDIRECTURL + "?p=huodong/" + ref;
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd0ed98b5f2e590fa&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
    //   提交
    onSubmit() {
      let ref = window.location.href;
      ref = Number(ref.substring(ref.indexOf("id=") + 3, ref.indexOf("#/")));
      getsignin({ ActivityID: ref }).then((res) => {
        if (res.code == 200) {
          Notify({
            type: "success",
            message: "签到成功",
          });
        }
        this.getDetails();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.reset {
  height: 100vh;
  background: #eef1f6;
}
.tips {
  padding: 16px;
  color: #333;
  font-size: 14px;
}
.van-css ::v-deep .van-field_body {
  color: #333;
}
</style>